import React, { useState } from "react";

import _ from "lodash";
import { createColumnHelper } from "@tanstack/react-table";
import { IssuesToolTip } from "/components/shared/IssuesTooltip";
import { getIndicatorName } from "../utils";
import Table from "/components/shared/table";
import CustomPopover from "/components/shared/popover";
import Notice from "/components/shared/Notice";

export default function ClaimsTable({ claims, isLoading, handleRowClick }) {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const handleClick = (selection) => {
    handleRowClick(selection.row);
    setSelectedRowIndex(selection.row.index);
  };

  const columnHelper = createColumnHelper();

  const idPopOverClick = (value) => {
    navigator.clipboard.writeText(value);
    setNotice({
      kind: "success",
      open: true,
      message: "ID copied to clipboard",
    });
  };

  const renderIdPopoverContent = (value) => {
    return (
      <div className="content p--5 flex popover__copy">
        <span className="material-symbols-outlined">file_copy</span>
        <span className="t--tny">{value}</span>
      </div>
    );
  };

  const columns = [
    columnHelper.accessor((row) => row.beacon_id, {
      header: "Beacon ID",
      enableSorting: false,
      cell: ({ renderValue }) => (
        <div className="t--sm">
          <CustomPopover
            value={renderValue().substring(0, 12)}
            popoverContent={renderIdPopoverContent(renderValue())}
            hoverable={true}
            onClickCallback={() => idPopOverClick(renderValue())}
          />
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.allocated, {
      header: "Credit",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return getValue() && <div className="t--md t--center t--green material-symbols-outlined">check_circle</div>;
      },
    }),
    columnHelper.accessor((row) => row.id_340b, {
      header: "340B ID",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.pharmacy, {
      header: "Facility",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue().id}</div>,
    }),
    columnHelper.accessor((row) => row.product_name, {
      header: "Ndc",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="flex flex-col item-start">
            <div className="t--sm t--500 t--left">{getValue()}</div>
            <div className="t--sm">{row.original.ndc}</div>
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.balance, {
      header: "Balance",
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.submissions, {
      header: "Submissions",
      cell: ({ getValue }) => {
        return (
          <div className="badge">
            <div className={`badge__indicator`} />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.quality, {
      header: "Result",
      enableSorting: false,
      cell: ({ getValue }) => {
        return (
          <div className="badge">
            <div
              className={`badge__indicator ${
                getValue().toLowerCase() === "conforming" ? "badge__indicator--green" : "badge__indicator--red"
              }`}
            />
            {getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.issues, {
      header: "Issues",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        if (_.size(getValue()) > 0) {
          return <IssuesToolTip row={row} getValue={getValue} />;
        }
        return (
          <div className="badge">
            <div className="badge__indicator badge__indicator--green" />
            {_.size(getValue())}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.date_submitted, {
      header: "Submitted",
      cell: ({ renderValue, row }) => (
        <>
          {/* <TableButtons
            row={row}
            hoveredRow={hoveredRow}
            openRestoreModal={openRestoreModal}
            openWithdrawModal={openWithdrawModal}
            openModal={openModal}
          /> */}
          <div className="table__text__stack">
            <div className="t--sm t--500">{row.original.submitted_by}</div>
            <div className="t--sm t--subtitle">{renderValue()}</div>
          </div>
        </>
      ),
    }),
  ];

  const tableOptions = {};

  return (
    <>
      <Table
        data={claims}
        columns={columns}
        tableOptions={tableOptions}
        onRowClick={handleClick}
        isLoading={isLoading}
        enableHover={true}
        noResultsMessage="The current search and filter criteria returned no results"
        layoutFixed={true}
        selectedRowIndex={selectedRowIndex}
      />
      <Notice details={notice} />
    </>
  );
}
