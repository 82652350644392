import httpClient from "../utils/httpClient";

export const downloadReport = async ({ filters, setIsDownloading, download_path, idFilter, invoiceFilter }) => {
  const controller = new AbortController();
  setIsDownloading(true);
  try {
    const res = await httpClient.post(
      download_path,
      { ...filters, ids: idFilter, invoice_numbers: invoiceFilter },
      { signal: controller.signal, responseType: "blob" },
    );

    if (!res) {
      throw new Error("Failed to download file");
    }

    const blob = new Blob([res.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "download.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (err) {
    console.error(err);
  } finally {
    setIsDownloading(false);
  }
};
