import React, { useState, useEffect } from "react";
import Filters from "/components/shared/filters";
import httpClient from "/components/utils/httpClient";
import { stringify } from "qs";
import _ from "lodash";
import PurchaseDetails from "./purchase_details";
import PurchasesTable from "./tables/purchases_table";
import Notice from "../../shared/Notice";
import { downloadReport } from "../../shared/downloadReport";

const PurchaseReport = (props) => {
  const [selectedLineItem, setSelectedLineItem] = useState(null);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [invoiceFilter, setInvoiceFilter] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsLoading(true);

    const params = stringify(filters);
    history.pushState(filters, "", "/purchases/reports?" + params);

    httpClient
      .post(
        props.query_path,
        { ...filters, ids: idFilter, invoice_numbers: invoiceFilter },
        {
          signal: controller.signal,
        },
      )
      .then((res) => {
        setInvoices(res.data.purchases);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error({ err });
      });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter, invoiceFilter]);

  const download = async () => {
    await downloadReport({
      filters,
      setIsDownloading,
      download_path: props.download_path,
      idFilter,
      invoiceFilter,
    });
  };
  const reload = async () => {
    setIsLoading(true);
    try {
      const res = await httpClient.post(props.query_path, {
        ...filters,
        ids: idFilter,
        invoice_numbers: invoiceFilter,
      });

      setInvoices(res.data.purchases);
    } catch (error) {
      console.error({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const reverse = async (path, newQuantity) => {
    setIsLoading(true);
    try {
      const res = await httpClient.post(path, { quantity: newQuantity });

      setNotice({
        kind: "success",
        open: true,
        message: res.data.success,
      });

      setSelectedLineItem(res.data.purchase);
    } catch (err) {
      console.error({ err });
      setNotice({
        kind: "error",
        open: true,
        message: err.response?.data?.error || "An error occured",
      });
    } finally {
      reload();
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Purchase Report</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Filters
            kind="invoice"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            invoiceFilterCallback={setInvoiceFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            isLoading={isDownloading}
            download={download}
          />
        </div>
      </div>
      <div className="body__content body__content">
        <div className="panel panel--pad panel--no-btm">
          <PurchasesTable invoice={invoices} setSelectedLineItem={setSelectedLineItem} isLoading={isLoading} />
        </div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <PurchaseDetails line={selectedLineItem} reverse={reverse} loading={isLoading} />
        </div>
        <Notice details={notice} />
      </div>
    </>
  );
};

export default PurchaseReport;
