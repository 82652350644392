import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { DragDropContext } from "react-beautiful-dnd";

import useVault from "../../hooks/useVault";
import httpClient from "/components/utils/httpClient";
import FileProcessor from "../../processors/file_processor";

import ColumnMappingNotice from "./column_mapping_notice";
import DataOutput from "./data_output";
import FieldMappings from "./field_mappings";
import Notice from "~/components/shared/Notice";
import Tabs from "~/components/shared/tabs";
import DataInput from "./data_input";
import HelpDropdown from "./help_dropdown";
import ConfirmModal from "/components/modals/ConfirmModal";
import DiscardChangesHelper from "/components/submissions/utils/discard_changes_helper";

const DataSubmission = (props) => {
  const [submissions, setSubmissions] = useState([]);
  // Should files be useState or useRef to prevent rerenders?
  const [files, setFiles] = useState([]);

  const fileInputRef = useRef();
  const [selectedAttachmentId, setSelectedAttachmentId] = useState(null);
  const [scrollTo, setScrollTo] = useState(() => {});
  const [displayTabBarTags, setDisplayTabBarTags] = useState(false);
  const [displayDiscardChangesModal, setDisplayDiscardChangesModal] = useState(false);

  const [notice, setNotice] = useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [selectedTab, setSelectedTab] = useState("submission");

  const [fieldMappingGroups, setFieldMappingGroups] = useState(props.fieldMappings);
  const [newMapping, setNewMapping] = useState(false);
  const [editState, setEditState] = useState({
    id: null,
    position: null,
    name: "",
    mappings: {},
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const usedFields = Object.values(editState.mappings);

  const discardController = useRef(undefined);

  const salt = useVault(props.organization, setNotice);

  const tabs = [
    { id: "submission", label: "Submission" },
    { id: "original_view", label: "Original File" },
    { id: "mappings", label: "Column Mappings" },
  ];

  useEffect(() => {
    if (fieldMappingGroups.length === 0) {
      setDisplayTabBarTags(true);
    } else {
      setDisplayTabBarTags(false);
    }

    /*
    Reprocesses all files when any FieldMapping is created/updated/deleted.
    Ideally we would like to only reprocess affected files.
    */
    async function reprocessFiles() {
      const newSubmissions = await Promise.all(
        submissions.map(async (submission) => {
          const file = files.find((file) => file.id === submission.file_id);

          const result = await FileProcessor({
            id: submission.id,
            file: file,
            kind: props.type + (props.encounter ? "_encounter" : ""),
            filterLists: props.filterLists,
            columnMappings: fieldMappingGroups,
            salt: salt,
          });

          return result;
        }),
      );

      setSubmissions(newSubmissions);
    }

    reprocessFiles();
  }, [fieldMappingGroups]);

  useEffect(() => {
    DiscardChangesHelper.getAllLinkElementsOnPage();

    const setDefaultSelectedAttachment = () => {
      if (submissions.length && !selectedAttachmentId) {
        setSelectedAttachmentId(submissions[0].id);
      }
    };

    setDefaultSelectedAttachment();
  }, []);

  // If in edit state of either editing mappings or attached file, register listeners for when user tries to leave without saving / finishing
  useEffect(() => {
    // no listeners already defined, and user in editing state (attached file or mapping edit)
    if (!discardController.current && (files.length > 0 || editState.position != null)) {
      // returns controller to use for removing listeners
      discardController.current = DiscardChangesHelper.addDiscardEventListeners(setDisplayDiscardChangesModal);
    }

    // left edit state
    if (files.length == 0 && editState.position == null) {
      DiscardChangesHelper.removeDiscardEventListeners(discardController.current);
      discardController.current = undefined;
    }
  }, [editState, files, discardController.current]);

  const renderSelectedTab = () => {
    switch (selectedTab) {
      case "submission":
        return (
          <DataOutput
            viewOriginal={false}
            salt={salt}
            organizationID={props.organization}
            data={submissions}
            type={props.type}
            encounter={props.encounter}
            mappings={fieldMappingGroups}
            attachedCount={1}
            displayTabBarTags={setTabBarTag}
            setScrollTo={setScrollTo}
            headers={props.headers}
          />
        );
      case "original_view":
        return (
          <DataOutput
            viewOriginal={true}
            salt={salt}
            organizationID={props.organization}
            data={submissions}
            type={props.type}
            encounter={props.encounter}
            mappings={fieldMappingGroups}
            attachedCount={1}
            displayTabBarTags={setTabBarTag}
            setScrollTo={setScrollTo}
            headers={props.headers}
            originalViewId={selectedAttachmentId}
            setOriginalViewId={setSelectedAttachmentId}
            setSelectedTab={setSelectedTab}
          />
        );
      case "mappings":
        return (
          <FieldMappings
            organization={props.organization}
            setNewMapping={setNewMapping}
            newMapping={newMapping}
            createFieldMapping={createFieldMapping}
            deleteFieldMappingGroup={deleteFieldMappingGroup}
            updateFieldMapping={updateFieldMapping}
            mappings={fieldMappingGroups}
            setMappings={setFieldMappingGroups}
            columnDetails={props.columnDetails}
            editState={editState}
            setEditState={setEditState}
            isDeleting={isDeleting}
            isSaving={isSaving}
            notRequired={props.notRequired}
          />
        );
    }
  };

  const getNextId = (collection) => {
    const lastItem = _.maxBy(collection, "id");
    if (lastItem) {
      return lastItem.id + 1;
    }

    return 1;
  };

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleFile = async (file) => {
    const fileObj = { id: getNextId(files), data: file };

    const newSubmissionId = getNextId(submissions);

    const result = await FileProcessor({
      id: newSubmissionId,
      file: fileObj,
      kind: props.type + (props.encounter ? "_encounter" : ""),
      filterLists: props.filterLists,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
    });

    setFiles((files) => [...files, fileObj]);
    setSubmissions((prev) => [...prev, result]);
    setSelectedAttachmentId(result.id);

    if (result.hasErrors) {
      setSelectedTab("mappings");
    }
  };

  const getSelectedMapping = (id) => {
    return _.find(fieldMappingGroups, ["id", id]);
  };

  // Reads file, adds object to state, and updates position of state.selected file to the new file
  const handleSelectMapping = async (submissionId, fileId, mappingId) => {
    const file = files.find((file) => file.id === fileId);

    if (!file) {
      return;
    }

    const result = await FileProcessor({
      id: submissionId,
      file: file,
      kind: props.type + (props.encounter ? "_encounter" : ""),
      filterLists: props.filterLists,
      filterKey: props.filterKey,
      columnMappings: fieldMappingGroups,
      salt: salt,
      selectedMapping: getSelectedMapping(mappingId),
    });

    const newSubmissions = submissions.map((submission) => {
      if (submission.id === submissionId) {
        return result;
      }

      return submission;
    });

    setSubmissions(newSubmissions);
  };

  const createFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      const res = await httpClient.post(`/field_mappings/${props.type}`, {
        field_mappings: { ...mapping.mappings, name: mapping.name },
      });

      setFieldMappingGroups(res.data.mappings);
    } catch (error) {
      console.error({ error });
      setNotice({
        kind: "error",
        open: true,
        message: "Oh no - it looks like something went wrong",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const updateFieldMapping = async (mapping) => {
    setIsSaving(true);
    try {
      const res = await httpClient.patch(`/field_mappings/${props.type}/${mapping.id}`, {
        field_mappings: { ...mapping.mappings, name: mapping.name },
      });
      setFieldMappingGroups(res.data.mappings);
    } catch (error) {
      console.error({ error });
      setNotice({
        kind: "error",
        open: true,
        message: "Oh no - it looks like something went wrong",
      });
    } finally {
      setIsSaving(false);
    }
  };

  const deleteFieldMappingGroup = async (id) => {
    setIsDeleting(true);

    try {
      const res = await httpClient.delete(`/field_mappings/${props.type}/${id}`);
      setFieldMappingGroups(res.data.mappings);
    } catch (error) {
      console.error({ error });
      setNotice({
        kind: "error",
        open: true,
        message: "Oh no - it looks like something went wrong",
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    const dragItemDetails = result.draggableId.split("#");

    var dropZoneDetails = destination.droppableId.split("#");

    if (dropZoneDetails[0] === "existing") {
      setEditState({
        ...editState,
        mappings: {
          ...editState.mappings,
          [dropZoneDetails[1]]: dragItemDetails[1],
        },
      });
    }
  };

  const setTabBarTag = (display) => {
    setDisplayTabBarTags(display);
  };

  const handleRemoveFile = (submission_id, file_id) => {
    const newSubmissions = submissions.filter((submission) => submission.id !== submission_id);

    if (submission_id === selectedAttachmentId) {
      const newId = newSubmissions.length ? newSubmissions[0].id : null;
      setSelectedAttachmentId(newId);
    }

    setSubmissions(newSubmissions);
    setFiles((files) => files.filter((file) => file.id !== file_id));
    fileInputRef.current.value = "";
  };

  const handleSelectAttachment = (submission_id) => {
    setSelectedAttachmentId(submission_id);
  };

  // When delete button clicked next to 'save and submit' removing all attachments
  const handleRemoveAllFiles = () => {
    setSubmissions([]);
    setFiles([]);
    setSelectedAttachmentId(null);
    fileInputRef.current.value = "";
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="panel panel--pad--sm panel--no-btm panel--submission">
          <Notice details={notice} />
          <Tabs items={tabs} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
          <ColumnMappingNotice isVisible={!fieldMappingGroups.length && files.length > 0} />
          {renderSelectedTab()}
        </div>
        <div className="panel panel--details panel--no-border panel--no-btm">
          <DataInput
            files={files}
            organization={props.organization}
            type={props.type}
            encounter={props.encounter}
            eligibility={props.eligibility}
            submissionPath={props.submissionPath}
            usedFields={usedFields}
            fieldMappingGroups={fieldMappingGroups}
            requiredShape={props.requiredShape}
            submissionData={submissions}
            handleFile={handleFile}
            handleRemoveFile={handleRemoveFile}
            ref={fileInputRef}
            mappingTabSelected={selectedTab == "mappings"}
            selectedAttachmentId={selectedAttachmentId}
            handleSelectAttachment={handleSelectAttachment}
            headers={props.headers}
            handleSelectMapping={handleSelectMapping}
            mappings={fieldMappingGroups}
            enableSubmissions={props.enableSubmissions}
            discardController={discardController}
            handleRemoveAllFiles={handleRemoveAllFiles}
          />
        </div>
      </DragDropContext>
      <ConfirmModal
        displayModal={displayDiscardChangesModal}
        setDisplayModal={setDisplayDiscardChangesModal}
        title="Leave and discard changes?"
        primaryActionText="Yes, Discard Changes"
        body="Please confirm if you want to leave this page. If you proceed, you will lose all of the files and changes you have not saved. This cannot be undone."
        primaryAction={() => {
          DiscardChangesHelper.userLeave(discardController.current);
          // allows for listeners to re-register if user cancels redirect
          discardController.current = undefined;
        }}
        secondaryAction={() => setDisplayDiscardChangesModal(false)}
      />
    </>
  );
};

export default DataSubmission;
