import React, { useState, useEffect, useCallback } from "react";
import httpClient from "/components/utils/httpClient";

const useVault = (organization, setNotice) => {
  const [salt, setSalt] = useState();

  useEffect(() => {
    fetchVault();
  }, [organization]);

  const fetchVault = useCallback(() => {
    if (salt && salt.length == 64) return;

    httpClient
      .get("/api/vault")
      .then((res) => setSalt(res.data.value))
      .catch((error) =>
        setNotice({ kind: "error", open: true, message: "Oh no - it looks like something went wrong" }),
      );
  }, [salt, organization, setNotice]);

  return salt;
};

export default useVault;
