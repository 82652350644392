import React, { useRef, useEffect, useState, useMemo } from "react";
import _ from "lodash";
import ClaimDetailsTable from "./table";
import TableRowDetails from "./table_row_details";
import Notice from "/components/shared/Notice";
import httpClient from "/components/utils/httpClient";
import Filters from "/components/shared/filters";
import { stringify } from "qs";
import { downloadReport } from "../../shared/downloadReport";

const PharmacyEncounterReport = (props) => {
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  const [filters, setFilters] = useState(props.filters);
  const [idFilter, setIdFilter] = useState([]);
  const [claims, setClaims] = useState([]);
  const [isClaimsLoading, setIsClaimsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const isMounted = useRef(false);

  useEffect(() => {
    const controller = new AbortController();
    setIsClaimsLoading(true);

    const params = stringify(filters);
    history.pushState(filters, "", "/claims/reports/pharmacy?" + params);
    httpClient
      .post(props.query_path, { ...filters, ids: idFilter }, { signal: controller.signal })
      .then((res) => {
        setClaims(res.data.claims);
        setIsClaimsLoading(false);
      })
      .catch((error) => {
        console.error({ error });
      });

    return () => {
      controller.abort();
    };
  }, [filters, idFilter]);

  useEffect(() => {
    if ((isMounted.current && !claims) || (isMounted.current && claims.length == 0)) {
      setNotice({
        kind: "success",
        open: true,
        message: "The current selection and filters returned no results",
      });
    }

    isMounted.current = true;
  }, [claims]);

  const download = async () => {
    await downloadReport({
      filters,
      setIsDownloading,
      download_path: props.download_path,
      idFilter,
    });
  };

  const claimView = (tab) => {
    return (
      <div key={tab} className="tabs__view tabs__view--active" data-tab-name={tab}>
        <ClaimDetailsTable claims={claims} isLoading={isClaimsLoading} handleRowClick={setSelectedRow} />
      </div>
    );
  };

  return (
    <>
      <div className="body__head">
        <div className="body__head__title">Pharmacy Claims Report</div>
        <div className="container container--shrink">
          <Filters
            kind="pharmacy"
            filterCallback={setFilters}
            idFilterCallback={setIdFilter}
            defaultFilters={props.filters}
            filterLists={props.filterLists}
            download={download}
            isLoading={isDownloading}
          />
        </div>
      </div>
      <div className="body__content">
        <div className="panel panel--pad panel--no-btm">{claimView()}</div>
        <div className="panel panel--info panel--pad panel--no-btm">
          <TableRowDetails row={selectedRow} />
        </div>
      </div>
      <Notice details={notice} />
    </>
  );
};

export default PharmacyEncounterReport;
