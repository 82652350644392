import axios from "axios";

const beaconAxios = axios.create();
// Add a request interceptor
beaconAxios.interceptors.request.use(
  (config) => {
    config.headers["Accept"] = "application/json";
    config.headers["Content-Type"] = "application/json";
    config.headers["X-CSRF-Token"] = document?.querySelector('meta[name="csrf-token"]')?.getAttribute("content");

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Add a response interceptor
beaconAxios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

export default {
  get: beaconAxios.get,
  post: beaconAxios.post,
  put: beaconAxios.put,
  delete: beaconAxios.delete,
  patch: beaconAxios.patch,
};
