import React, { useEffect, useState } from "react";
import httpClient from "/components/utils/httpClient";
import AccountsTable from "../tables/accounts_table";
import Notice from "/components/shared/Notice";

const Claims = ({ path, isParentLoading, setSelectedClaim, setSelectedClaimIndex, selectedClaimIndex }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notice, setNotice] = React.useState({
    kind: "error",
    open: false,
    message: "",
  });

  useEffect(() => {
    setData([]);
    setIsLoading(true);
    httpClient
      .get(path)
      .then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((error) => console.error({ error }));
  }, [path]);

  useEffect(() => {
    setSelectedClaim(data ? data[0] : null);
    setSelectedClaimIndex(0);
  }, [data]);

  const handleSelectClaim = (event) => {
    setSelectedClaim(event.row.original);
    setSelectedClaimIndex(event.row.index);
  };

  const getIndicatorColor = (status) => {
    switch (status?.toLowerCase()) {
      case "paid":
        return "green";
      case "under review":
        return "orange";
      case "filled":
        return "green";
      case "allocated":
        return "green";
      case "reversed":
        return "red";
      default:
        return "blue";
    }
  };

  return (
    <>
      <AccountsTable
        isLoading={isLoading || isParentLoading}
        data={data}
        handleRowClick={handleSelectClaim}
        selectedRowIndex={selectedClaimIndex}
        getIndicatorColor={getIndicatorColor}
        setNotice={setNotice}
      />
      <Notice details={notice} />
    </>
  );
};

export default Claims;
