import React, { useState, useEffect } from "react";
import httpClient from "/components/utils/httpClient";
import QuantityHistoryTable from "./tables/quantity_history_table";

export const BalanceHistory = (props) => {
  const [historyLines, setHistoryLines] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        const response = await httpClient.get(props.balance_history_path);
        setHistoryLines(response.data.data);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    };

    getData();
  }, [props.balance_history_path]);

  return (
    <>
      <QuantityHistoryTable data={historyLines} isLoading={isLoading} />
    </>
  );
};
