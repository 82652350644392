import React, { useState, useRef, Fragment, useMemo } from "react";
import { UnfoldMore } from "@mui/icons-material";
import Search from "./search_bar";
import _ from "lodash";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getExpandedRowModel,
  getSortedRowModel,
  getPaginationRowModel,
} from "@tanstack/react-table";
import TableLoadingSkeleton from "../reports/table_loading_skeleton";

export default function ClaimsTable(props) {
  const [search, setSearch] = useState("");
  const tableContainerRef = useRef();

  const [sorting, setSorting] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.issue, {
      header: "Issue",
      enableSorting: false,
      cell: ({ renderValue }) => <div className="t--sm">{renderValue().label}</div>,
    }),
    columnHelper.accessor((row) => row.description, {
      header: "Details",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        return (
          <div className="t--sm">
            <div className="badge">{row.original.value1}</div>
            {getValue()}
            {row.original.value2 ? <div className="badge">{row.original.value2}</div> : null}
          </div>
        );
      },
    }),
    columnHelper.accessor((row) => row.count, {
      enableSorting: false,
      header: "Affected Claims",
      cell: ({ renderValue }) => <div className="t--sm">{renderValue()}</div>,
    }),
    columnHelper.accessor((row) => row.status, {
      header: "Status",
      enableSorting: false,
      cell: ({ renderValue }) => (
        <div className="badge">
          <div className={`badge__indicator badge__indicator--${props.getIndicatorColor(renderValue())}`} />
          {renderValue()}
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.document_name, {
      header: "Documents",
      enableSorting: false,
      cell: ({ row, getValue }) => {
        if (getValue()) return <div className="badge">{getValue()}</div>;
        return null;
      },
    }),
  ];

  const claims = useMemo(() => props.claims, [props.claims]);

  const table = useReactTable({
    data: claims,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getExpandedRowModel: getExpandedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    initialState: { pagination: { pageSize: 50 } },
    getPaginationRowModel: getPaginationRowModel(),
  });

  const renderPaginationControls = () => {
    return (
      <div className="flex__container" style={{ justifyContent: "flex-end", marginTop: 10 }}>
        <button className="btn btn--small" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
          Prev
        </button>
        <button
          className="btn btn--small"
          style={{ marginLeft: 10 }}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    );
  };

  const renderRows = () => {
    if (props.isLoading) return <TableLoadingSkeleton rows={20} columns={5} />;

    return table.getRowModel().rows.map((row) => {
      return (
        <Fragment key={row.id}>
          <tr
            className={`sm sm--hover ${row.original == props.dispute ? "sm--selected" : ""}`}
            onClick={() => props.setDispute(row.original)}
          >
            {row.getVisibleCells().map((cell) => (
              <td key={cell.id} className="t--md">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        </Fragment>
      );
    });
  };

  return (
    <>
      <Search issues={props.issues} setSearch={setSearch} />
      <div ref={tableContainerRef}>
        <table className="primary">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="sm">
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <span
                        style={{
                          fontSize: 12,
                          display: "inline-flex",
                          verticalAlign: "top",
                        }}
                        onClick={() => header.column.toggleSorting()}
                      >
                        <UnfoldMore fontSize="inherit" color="inherit" />
                      </span>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>{renderRows()}</tbody>
        </table>
        {renderPaginationControls()}
      </div>
    </>
  );
}
